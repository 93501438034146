var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"light-blue lighten-5 pb-16",attrs:{"fluid":""}},[_c('div',[_c('v-row',{staticClass:"mb-0 d-flex justify-center align-center"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","sm":"11","md":"9","lg":"9"}},[_c('v-card',{staticClass:"white px-auto pb-0 mb-0"},[_c('v-stepper',[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":"","complete-icon":"mdi-home-circle"}},[_vm._v(" CONDO INFO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2","complete":"","complete-icon":"mdi-home-circle"}},[_vm._v(" CHOOSE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" PROPERTY INFO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" COVERAGES ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"5"}},[_vm._v(" PURCHASE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"6"}},[_vm._v(" YOUR POLICY ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"title-text"},[_vm._v(" Choose A Plan That’s Right For You ")])])],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"11","md":"9","lg":"9"}},[_c('p',[_vm._v(" These rates are preliminary quotes to give you an idea on what your policy will cost. There are still discounts to be applied and coverage options to choose from. To continue, select the quote you prefer. ")]),_c('br'),(_vm.quoteWarning)?_c('p',{staticClass:"font-weight-bold red--text"},[_vm._v(" "+_vm._s(_vm.quoteWarning)+" ")]):_vm._e()])],1),_c('div',{staticClass:"hidden-xs-only"},[_c('v-row',{staticClass:"d-flex justify-center pb-16"},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-card',{staticClass:"pa-4 travel--text",attrs:{"width":"32%"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                'choosePlan premiumCardPlan',
                40,
                'Condo Survey v1'
              )}},model:{value:(_vm.premium),callback:function ($$v) {_vm.premium=$$v},expression:"premium"}},[_c('div',{staticClass:"plan-title text-center font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.smallQuotePremium)+"/yr ")]),_c('div',{staticClass:"plan-sub text-center font-weight-bold"},[_vm._v(" Payment Plans Available ")]),_c('v-divider',{staticClass:"my-2 travel"}),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.smallQuoteDeductible)+" "+_vm._s(_vm.smallQuoteHurricaneDeductible ? 'All Other' : '')+" "+_vm._s(_vm.quoteWarning && !_vm.smallQuoteHurricaneDeductible ? 'Excluding wind and hail' : 'Deductible')+" ")]),(_vm.smallQuoteHurricaneDeductible)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.smallQuoteHurricaneDeductible)+" Hurricane Deductible ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" This is what you will pay out of pocket if you have a claim ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.smallQuotePPR)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Personal Property Replacement Cost ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.smallQuoteLiability)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Liability ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"travel white--text font-weight-bold",on:{"click":function($event){_vm.setPlan(_vm.plans[0].name, _vm.smallQuoteNumber, _vm.smallQuote)
                    _vm.clickedFieldGlobal(
                      'choosePlan premiumCardPlan',
                      41,
                      'Condo Survey v1'
                    )}}},[_vm._v(" SELECT ")])],1)],1)],1),_c('v-card',{staticClass:"pa-4 primary--text",attrs:{"width":"32%"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                'choosePlan preferredCardPlan',
                42,
                'Condo Survey v1'
              )}},model:{value:(_vm.preferred),callback:function ($$v) {_vm.preferred=$$v},expression:"preferred"}},[_c('div',{staticClass:"plan-title text-center font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.mediumQuotePremium)+"/yr ")]),_c('div',{staticClass:"plan-sub font-weight-bold text-center"},[_vm._v(" Payment Plans Available ")]),_c('v-divider',{staticClass:"my-2 primary"}),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.mediumQuoteDeductible)+" "+_vm._s(_vm.mediumQuoteHurricaneDeductible ? 'All Other' : '')+" "+_vm._s(_vm.quoteWarning ? 'Excluding wind and hail' : 'Deductible')+" ")]),(_vm.mediumQuoteHurricaneDeductible)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.mediumQuoteHurricaneDeductible)+" Hurricane Deductible ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" This is what you will pay out of pocket if you have a claim ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.mediumQuotePPR)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Personal Property Replacement Cost ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.mediumQuoteLiability)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Liability ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary white--text font-weight-bold",on:{"click":function($event){_vm.setPlan(_vm.plans[1].name, _vm.mediumQuoteNumber, _vm.mediumQuote)
                    _vm.clickedFieldGlobal(
                      'choosePlan preferredCardPlan',
                      43,
                      'Condo Survey v1'
                    )}}},[_vm._v(" SELECT ")])],1)],1)],1),_c('v-card',{staticClass:"pa-4 secondary--text",attrs:{"width":"32%"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                'choosePlan standardCardPlan',
                44,
                'Condo Survey v1'
              )}},model:{value:(_vm.standard),callback:function ($$v) {_vm.standard=$$v},expression:"standard"}},[_c('div',{staticClass:"plan-title text-center font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.largeQuotePremium)+"/yr ")]),_c('div',{staticClass:"plan-sub font-weight-bold text-center"},[_vm._v(" Payment Plans Available ")]),_c('v-divider',{staticClass:"my-2 secondary"}),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.largeQuoteDeductible)+" "+_vm._s(_vm.largeQuoteHurricaneDeductible ? 'All Other' : '')+" "+_vm._s(_vm.quoteWarning ? 'Excluding wind and hail' : 'Deductible')+" ")]),(_vm.largeQuoteHurricaneDeductible)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.largeQuoteHurricaneDeductible)+" Hurricane Deductible ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" This is what you will pay out of pocket if you have a claim ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.largeQuotePPR)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Personal Property Replacement Cost ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.largeQuoteLiability)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Liability ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"secondary white--text font-weight-bold",on:{"click":function($event){_vm.setPlan(_vm.plans[2].name, _vm.largeQuoteNumber, _vm.largeQuote)
                    _vm.clickedFieldGlobal(
                      'choosePlan standardCardPlan',
                      45,
                      'Condo Survey v1'
                    )}}},[_vm._v(" SELECT ")])],1)],1)],1)],1)],1)],1),(_vm.mobileDevice)?_c('div',{staticClass:"mx-0 px-0"},[_c('v-slide-group',{staticClass:"mx-0 px-0"},[_c('v-card',{staticClass:"pa-4 travel--text mr-2",attrs:{"width":"75vw"},model:{value:(_vm.premium),callback:function ($$v) {_vm.premium=$$v},expression:"premium"}},[_c('div',{staticClass:"plan-title text-center font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.smallQuotePremium)+"/yr ")]),_c('div',{staticClass:"plan-sub text-center font-weight-bold"},[_vm._v(" Payment Plans Available ")]),_c('v-divider',{staticClass:"my-2 travel"}),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.smallQuoteDeductible)+" "+_vm._s(_vm.smallQuoteHurricaneDeductible ? 'All Other' : '')+" "+_vm._s(_vm.quoteWarning && !_vm.smallQuoteHurricaneDeductible ? 'Excluding wind and hail' : 'Deductible')+" ")]),(_vm.smallQuoteHurricaneDeductible)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.smallQuoteHurricaneDeductible)+" Hurricane Deductible ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" This is what you will pay out of pocket if you have a claim ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.smallQuotePPR)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Personal Property Replacement Cost ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.smallQuoteLiability)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Liability ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"travel white--text font-weight-bold",on:{"click":function($event){_vm.setPlan(_vm.plans[0].name, _vm.smallQuoteNumber, _vm.smallQuote)
                  _vm.clickedFieldGlobal(
                    'choosePlan premiumCardPlanMobile',
                    46,
                    'Condo Survey v1'
                  )}}},[_vm._v(" SELECT ")])],1)],1)],1),_c('v-card',{staticClass:"pa-4 primary--text mx-2",attrs:{"width":"75vw"},model:{value:(_vm.preferred),callback:function ($$v) {_vm.preferred=$$v},expression:"preferred"}},[_c('div',{staticClass:"plan-title text-center font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.mediumQuotePremium)+"/yr ")]),_c('div',{staticClass:"plan-sub font-weight-bold text-center"},[_vm._v(" Payment Plans Available ")]),_c('v-divider',{staticClass:"my-2 primary"}),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.mediumQuoteDeductible)+" "+_vm._s(_vm.mediumQuoteHurricaneDeductible ? 'All Other' : '')+" "+_vm._s(_vm.quoteWarning ? 'Excluding wind and hail' : 'Deductible')+" ")]),(_vm.mediumQuoteHurricaneDeductible)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.mediumQuoteHurricaneDeductible)+" Hurricane Deductible ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" This is what you will pay out of pocket if you have a claim ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.mediumQuotePPR)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Personal Property Replacement Cost ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.mediumQuoteLiability)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Liability ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary white--text font-weight-bold",on:{"click":function($event){_vm.setPlan(_vm.plans[1].name, _vm.mediumQuoteNumber, _vm.mediumQuote)
                  _vm.clickedFieldGlobal(
                    'choosePlan preferredCardPlanMobile',
                    47,
                    'Condo Survey v1'
                  )}}},[_vm._v(" SELECT ")])],1)],1)],1),_c('v-card',{staticClass:"pa-4 secondary--text ml-2",attrs:{"width":"75vw"},model:{value:(_vm.standard),callback:function ($$v) {_vm.standard=$$v},expression:"standard"}},[_c('div',{staticClass:"plan-title text-center font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.largeQuotePremium)+"/yr ")]),_c('div',{staticClass:"plan-sub font-weight-bold text-center"},[_vm._v(" Payment Plans Available ")]),_c('v-divider',{staticClass:"my-2 secondary"}),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.largeQuoteDeductible)+" "+_vm._s(_vm.largeQuoteHurricaneDeductible ? 'All Other' : '')+" "+_vm._s(_vm.quoteWarning ? 'Excluding wind and hail' : 'Deductible')+" ")]),(_vm.largeQuoteHurricaneDeductible)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.largeQuoteHurricaneDeductible)+" Hurricane Deductible ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" This is what you will pay out of pocket if you have a claim ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.largeQuotePPR)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Personal Property Replacement Cost ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.largeQuoteLiability)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Liability ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"secondary white--text font-weight-bold",on:{"click":function($event){_vm.setPlan(_vm.plans[2].name, _vm.largeQuoteNumber, _vm.largeQuote)
                  _vm.clickedFieldGlobal(
                    'choosePlan standardCardPlanMobile',
                    48,
                    'Condo Survey v1'
                  )}}},[_vm._v(" SELECT ")])],1)],1)],1),_c('v-col')],1)],1):_vm._e()],1),_c('error-dialog',{attrs:{"error-dialog":_vm.errorDialog,"error-message":_vm.error}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }