<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!------------------------------------STEPPER-------------------------------------->
    <div>
      <v-row class="mb-0 d-flex justify-center align-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="pb-0 mb-0"
        >
          <v-card class="white px-auto pb-0 mb-0">
            <v-stepper>
              <v-stepper-header>
                <v-stepper-step
                  step="1"
                  complete
                  complete-icon="mdi-home-circle"
                >
                  CONDO INFO
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  step="2"
                  complete
                  complete-icon="mdi-home-circle"
                >
                  CHOOSE PLAN
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  PROPERTY INFO
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step step="4">
                  COVERAGES
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="5">
                  PURCHASE PLAN
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="6">
                  YOUR POLICY
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
      <!-- LOGIN -->
      <!-- <div v-if="!userpresent && !loading">
        <v-row class="justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
          >
            <v-card>
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <v-col
                    class="d-flex justify-center text-h3 text-center primary--text mb-6 mt-6"
                  >
                    In order to continue with the application please log in or
                    create an account.
                  </v-col>
                </v-row>
                <v-row v-if="error">
                  {{ error }}
                </v-row>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <validation-observer
                        ref="observer"
                        v-slot="{ invalid }"
                      >
                        <v-row class="d-flex justify-center">
                          <v-col class="pb-0">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <v-text-field
                                v-model="user.email"
                                label="Email"
                                outlined
                                append-icon="mdi-email-outline"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'choosePlan user.email',
                                    36,
                                    'Condo Survey v1'
                                  )
                                "
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col class="pb-0">
                            <validation-provider
                              v-slot="{ errors }"
                              vid="password"
                              name="Password"
                              rules="required"
                            >
                              <v-text-field
                                ref="password"
                                v-model="user.password"
                                name="password"
                                label="Password"
                                outlined
                                :type="show ? 'text' : 'password'"
                                :append-icon="
                                  show ? 'mdi-eye-outline' : 'mdi-eye-off'
                                "
                                :error-messages="errors"
                                @click:append="
                                  show = !show
                                  clickedFieldGlobal(
                                    'choosePlan user.password',
                                    37,
                                    'Condo Survey v1'
                                  )
                                "
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col
                            cols="6"
                            class="d-flex justify-center"
                          >
                            <v-btn
                              class="primary whtie--text mr-0"
                              block
                              large
                              :disabled="invalid"
                              @keyup.enter="submit"
                              @click="
                                loginSubmit()
                                clickedFieldGlobal(
                                  'choosePlan logIn',
                                  38,
                                  'Condo Survey v1'
                                )
                              "
                            >
                              <span class="login-btn">Log In</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </validation-observer>
                    </v-form>
                    <v-row class="d-flex justify-center">
                      <v-col>
                        <div class="signup-text text-center">
                          <span
                            style="color: #757575;"
                          >Don't have an account?
                          </span>
                          <v-btn
                            text
                            class="primary--text font-weight-bold"
                            :disabled="invalidEmail"
                            @click="
                              createAccount()
                              clickedFieldGlobal(
                                'choosePlan createAccount',
                                39,
                                'Condo Survey v1'
                              )
                            "
                          >
                            Create One For Me using: {{ user.email }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div> -->

      <!-----------------------------------------TITLE-------------------------------------->
      <v-row class="mt-6">
        <v-col class="text-center">
          <div class="title-text">
            Choose A Plan That’s Right For You
          </div>
        </v-col>
      </v-row>
      <!--------------------------------------PLAN CARDS------------------------------------>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="text-center"
        >
          <p>
            These rates are preliminary quotes to give you an idea on what your
            policy will cost. There are still discounts to be applied and
            coverage options to choose from. To continue, select the quote you
            prefer.
          </p>
          <br />
          <p
            v-if="quoteWarning"
            class="font-weight-bold red--text"
          >
            {{ quoteWarning }}
          </p>
        </v-col>
      </v-row>
      <div class="hidden-xs-only">
        <v-row class="d-flex justify-center pb-16">
          <v-col
            cols="12"
            sm="12"
            md="9"
            class="d-flex justify-space-between"
          >
            <!---------------------CARD1----------------------->
            <v-card
              v-model="premium"
              class="pa-4 travel--text"
              width="32%"
              @click="
                clickedFieldGlobal(
                  'choosePlan premiumCardPlan',
                  40,
                  'Condo Survey v1'
                )
              "
            >
              <div class="plan-title text-center font-weight-bold">
                ${{ smallQuotePremium }}/yr
              </div>
              <div class="plan-sub text-center font-weight-bold">
                Payment Plans Available
              </div>
              <v-divider class="my-2 travel"></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    ${{ smallQuoteDeductible }}
                    {{ smallQuoteHurricaneDeductible ? 'All Other' : '' }}
                    {{
                      quoteWarning && !smallQuoteHurricaneDeductible
                        ? 'Excluding wind and hail'
                        : 'Deductible'
                    }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="smallQuoteHurricaneDeductible"
                    class="font-weight-bold"
                  >
                    ${{ smallQuoteHurricaneDeductible }} Hurricane Deductible
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    This is what you will pay out of pocket if you have a claim
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ smallQuotePPR }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Personal Property Replacement Cost
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ smallQuoteLiability }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Liability
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="travel white--text font-weight-bold"
                    @click="
                      setPlan(plans[0].name, smallQuoteNumber, smallQuote)
                      clickedFieldGlobal(
                        'choosePlan premiumCardPlan',
                        41,
                        'Condo Survey v1'
                      )
                    "
                  >
                    SELECT
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <!---------------------CARD2----------------------->
            <v-card
              v-model="preferred"
              class="pa-4 primary--text"
              width="32%"
              @click="
                clickedFieldGlobal(
                  'choosePlan preferredCardPlan',
                  42,
                  'Condo Survey v1'
                )
              "
            >
              <div class="plan-title text-center font-weight-bold">
                ${{ mediumQuotePremium }}/yr
              </div>
              <div class="plan-sub font-weight-bold text-center">
                Payment Plans Available
              </div>
              <v-divider class="my-2 primary"></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    ${{ mediumQuoteDeductible }}
                    {{ mediumQuoteHurricaneDeductible ? 'All Other' : '' }}
                    {{
                      quoteWarning ? 'Excluding wind and hail' : 'Deductible'
                    }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="mediumQuoteHurricaneDeductible"
                    class="font-weight-bold"
                  >
                    ${{ mediumQuoteHurricaneDeductible }} Hurricane Deductible
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    This is what you will pay out of pocket if you have a claim
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ mediumQuotePPR }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Personal Property Replacement Cost
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ mediumQuoteLiability }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Liability
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="primary white--text font-weight-bold"
                    @click="
                      setPlan(plans[1].name, mediumQuoteNumber, mediumQuote)
                      clickedFieldGlobal(
                        'choosePlan preferredCardPlan',
                        43,
                        'Condo Survey v1'
                      )
                    "
                  >
                    SELECT
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <!---------------------CARD3----------------------->
            <v-card
              v-model="standard"
              class="pa-4 secondary--text"
              width="32%"
              @click="
                clickedFieldGlobal(
                  'choosePlan standardCardPlan',
                  44,
                  'Condo Survey v1'
                )
              "
            >
              <div class="plan-title text-center font-weight-bold">
                ${{ largeQuotePremium }}/yr
              </div>
              <div class="plan-sub font-weight-bold text-center">
                Payment Plans Available
              </div>
              <v-divider class="my-2 secondary"></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    ${{ largeQuoteDeductible }}
                    {{ largeQuoteHurricaneDeductible ? 'All Other' : '' }}
                    {{
                      quoteWarning ? 'Excluding wind and hail' : 'Deductible'
                    }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="largeQuoteHurricaneDeductible"
                    class="font-weight-bold"
                  >
                    ${{ largeQuoteHurricaneDeductible }} Hurricane Deductible
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    This is what you will pay out of pocket if you have a claim
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ largeQuotePPR }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Personal Property Replacement Cost
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ largeQuoteLiability }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Liability
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="secondary white--text font-weight-bold"
                    @click="
                      setPlan(plans[2].name, largeQuoteNumber, largeQuote)
                      clickedFieldGlobal(
                        'choosePlan standardCardPlan',
                        45,
                        'Condo Survey v1'
                      )
                    "
                  >
                    SELECT
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-----------------------------------------------Mobile---------------------------------------->
      <div
        v-if="mobileDevice"
        class="mx-0 px-0"
      >
        <v-slide-group class="mx-0 px-0">
          <v-card
            v-model="premium"
            class="pa-4 travel--text mr-2"
            width="75vw"
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ smallQuotePremium }}/yr
            </div>
            <div class="plan-sub text-center font-weight-bold">
              Payment Plans Available
            </div>
            <v-divider class="my-2 travel"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  ${{ smallQuoteDeductible }}
                  {{ smallQuoteHurricaneDeductible ? 'All Other' : '' }}
                  {{
                    quoteWarning && !smallQuoteHurricaneDeductible
                      ? 'Excluding wind and hail'
                      : 'Deductible'
                  }}
                </v-list-item-title>
                <v-list-item-title
                  v-if="smallQuoteHurricaneDeductible"
                  class="font-weight-bold"
                >
                  ${{ smallQuoteHurricaneDeductible }} Hurricane Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ smallQuotePPR }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Personal Property Replacement Cost
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ smallQuoteLiability }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="travel white--text font-weight-bold"
                  @click="
                    setPlan(plans[0].name, smallQuoteNumber, smallQuote)
                    clickedFieldGlobal(
                      'choosePlan premiumCardPlanMobile',
                      46,
                      'Condo Survey v1'
                    )
                  "
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-model="preferred"
            class="pa-4 primary--text mx-2"
            width="75vw"
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ mediumQuotePremium }}/yr
            </div>
            <div class="plan-sub font-weight-bold text-center">
              Payment Plans Available
            </div>
            <v-divider class="my-2 primary"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  ${{ mediumQuoteDeductible }}
                  {{ mediumQuoteHurricaneDeductible ? 'All Other' : '' }}
                  {{ quoteWarning ? 'Excluding wind and hail' : 'Deductible' }}
                </v-list-item-title>
                <v-list-item-title
                  v-if="mediumQuoteHurricaneDeductible"
                  class="font-weight-bold"
                >
                  ${{ mediumQuoteHurricaneDeductible }} Hurricane Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ mediumQuotePPR }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Personal Property Replacement Cost
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ mediumQuoteLiability }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="primary white--text font-weight-bold"
                  @click="
                    setPlan(plans[1].name, mediumQuoteNumber, mediumQuote)
                    clickedFieldGlobal(
                      'choosePlan preferredCardPlanMobile',
                      47,
                      'Condo Survey v1'
                    )
                  "
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-model="standard"
            class="pa-4 secondary--text ml-2"
            width="75vw"
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ largeQuotePremium }}/yr
            </div>
            <div class="plan-sub font-weight-bold text-center">
              Payment Plans Available
            </div>
            <v-divider class="my-2 secondary"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  ${{ largeQuoteDeductible }}
                  {{ largeQuoteHurricaneDeductible ? 'All Other' : '' }}
                  {{ quoteWarning ? 'Excluding wind and hail' : 'Deductible' }}
                </v-list-item-title>
                <v-list-item-title
                  v-if="largeQuoteHurricaneDeductible"
                  class="font-weight-bold"
                >
                  ${{ largeQuoteHurricaneDeductible }} Hurricane Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ largeQuotePPR }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Personal Property Replacement Cost
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ largeQuoteLiability }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="secondary white--text font-weight-bold"
                  @click="
                    setPlan(plans[2].name, largeQuoteNumber, largeQuote)
                    clickedFieldGlobal(
                      'choosePlan standardCardPlanMobile',
                      48,
                      'Condo Survey v1'
                    )
                  "
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-col> </v-col>
        </v-slide-group>
      </div>
    </div>
    <error-dialog
      :error-dialog="errorDialog"
      :error-message="error"
    ></error-dialog>
  </v-container>
</template>

<script>
  import { emailRegex } from '@/consts/regex'
  var numeral = require('numeral')
  export default {
    data () {
      return {
        show: '',
        user: {
          email: '',
          password: '',
        },
        errorDialog: false,
        standard: '',
        premium: '',
        preferred: '',
        selectedPlan: '',
        plans: [
          {
            name: 'Premium',
            price: '$1,697.00/yr',
          },
          {
            name: 'Preferred',
            price: '$1,839.00/yr',
          },
          {
            name: 'Standard',
            price: '$2,100.00/yr',
          },
        ],
      }
    },
    computed: {
      invalidEmail () {
        let invalid = true
        if (emailRegex.test(this.user.email)) {
          invalid = false
        }
        return invalid
      },
      error () {
        let error = this.$store.getters.error
        if (error) {
          this.errorDialog = true
          return error
        }
        return false
      },
      quoteWarning () {
        return this.$store.getters.getCondoQuoteWarning
      },
      loading () {
        return this.$store.getters.getloading
      },
      userpresent () {
        let confirmedUser = false
        console.log('confirmed user ', confirmedUser, this.$store.getters.getuser)
        if (this.$store.getters.getuser !== null) {
          confirmedUser = true
        }
        console.log('confirmed user ', confirmedUser)
        return confirmedUser
      },
      owner () {
        return this.$store.getters.getCondoOwner
      },
      smallQuoteNumber () {
        return this.$store.getters.getSmallCondoQuoteNumber
      },
      mediumQuoteNumber () {
        return this.$store.getters.getMediumRentalCondoNumber
      },
      largeQuoteNumber () {
        return this.$store.getters.getLargeRentalCondoNumber
      },
      smallQuoteHurricaneDeductible () {
        let deductible = ''
        if (
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible !== undefined
        ) {
          deductible = numeral(
            this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
              .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible[0]
              .FormatInteger[0]
          ).format('0.00')
        }
        return deductible
      },
      mediumQuoteHurricaneDeductible () {
        let deductible = ''
        if (
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible !== undefined
        ) {
          deductible = numeral(
            this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
              .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible[0]
              .FormatInteger[0]
          ).format('0.00')
        }
        return deductible
      },
      largeQuoteHurricaneDeductible () {
        let deductible = ''
        if (
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible !== undefined
        ) {
          deductible = numeral(
            this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
              .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible[0]
              .FormatInteger[0]
          ).format('0.00')
        }
        return deductible
      },
      smallQuoteDeductible () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0]
            .FormatInteger[0]
        ).format('0,0.00')
      },
      mediumQuoteDeductible () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0]
            .FormatInteger[0]
        ).format('0,0.00')
      },
      largeQuoteDeductible () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0]
            .FormatInteger[0]
        ).format('0,0.00')
      },
      smallQuotePPR () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      mediumQuotePPR () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      largeQuotePPR () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      smallQuoteLiability () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[3].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      mediumQuoteLiability () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[3].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      largeQuoteLiability () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[3].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      smallQuotePremium () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      mediumQuotePremium () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      largeQuotePremium () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      smallQuote () {
        return this.$store.getters.getSmallCondoQuote
      },
      mediumQuote () {
        return this.$store.getters.getMediumCondoQuote
      },
      largeQuote () {
        return this.$store.getters.getLargeCondoQuote
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      premiumSelected () {
        return this.selectedPlan === 'Premium'
      },
      preferredSelected () {
        return this.selectedPlan === 'Preferred'
      },
      standardSelected () {
        return this.selectedPlan === 'Standard'
      },
    },
    created () {
      console.log(this.largeQuote)
      console.log(this.mediumQuote)
      console.log(this.smallQuote)
      console.log('Owner is ', this.owner)
      this.user.email = this.owner.communications.emailInfo.emailAddr
    },
    methods: {
      createAccount () {
        // put account creation function here
        console.log('creating account with ', this.user.email)
        this.$store.dispatch('createUserAndProfileForUser', {
          email: this.user.email,
        })
      },
      loginSubmit () {
        // log user in
        console.log('login with ', this.user.email, this.user.password)
        this.$store.dispatch('signUserIn', {
          email: this.user.email,
          password: this.user.password,
        })
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      setPlan (plan, quoteNumber, quote) {
        this.selectedPlan = plan
        let owner = Object.assign(this.$store.getters.getRenter, {
          quoteNumber: quoteNumber,
          quote: quote,
        })
        console.log(owner)
        this.$store.dispatch('setCondoQuote', quote)
        this.$router.push({ path: '/condo/propertyinfo' })
      },
    },
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #d8fdd7;
}
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00a1b7;
}
.plan-title {
  font-size: 33px;
}
.plan-sub {
  font-size: 18px;
}
.selected {
  background-color: #005f6c;
  color: white;
}
.white {
  color: white;
}

.center {
  justify-content: center;
}
.left {
  justify-content: start;
}
.end {
  justify-content: end;
}

::v-deep .v-list-item--three-line .v-list-item__subtitle {
  white-space: normal !important;
  overflow-wrap: break-word normal !important;
  word-wrap: break-word normal !important;
  -webkit-hyphens: auto normal !important;
  -moz-hyphens: auto normal !important;
  -webkit-line-clamp: unset !important;
  hyphens: auto normal !important;
}

::v-deep .v-list-item__subtitle {
  white-space: normal !important;
  overflow-wrap: break-word normal !important;
  word-wrap: break-word normal !important;
  -webkit-hyphens: auto normal !important;
  -moz-hyphens: auto normal !important;
  -webkit-line-clamp: unset !important;
  hyphens: auto normal !important;
}

::v-deep .v-list-item .v-list-item__title {
  white-space: normal !important;
  overflow-wrap: break-word normal !important;
  word-wrap: break-word normal !important;
  -webkit-hyphens: auto normal !important;
  -moz-hyphens: auto normal !important;
  -webkit-line-clamp: unset !important;
  hyphens: auto normal !important;
}

::v-deep .v-list-item__title {
  white-space: normal !important;
}

::v-deep .v-select__selection--comma {
  white-space: normal !important;
}
</style>
